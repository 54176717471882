import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "./Constants";

const RoleBasedRoute = ({ role, allowedRoles, children }) => {
  if (!allowedRoles.includes(role)) {
    // Redirect to not authorized page or login page
    return <Navigate to="/not-authorized" />;
  }
  return children;
};

export default RoleBasedRoute;

// Encrypt key with value and store in specified storage
export const encrypt = (key, value, storage) => {
  const encrypted = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
  switch (storage) {
    case "L":
      localStorage.setItem(key, encrypted);
      break;
    case "S":
      sessionStorage.setItem(key, encrypted);
      break;
    case "B":
      localStorage.setItem(key, encrypted);
      sessionStorage.setItem(key, encrypted);
      break;
    default:
      break;
  }
};

// Decrypt value from the specified storage
export const decrypt = (key) => {
  const encrypted = localStorage.getItem(key) || sessionStorage.getItem(key);
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  }
  return null;
};

export const checkAccess = (module, accessType = "") => {
  const moduleArray = decrypt("PRIVILEGES");
  const accessTypeMapping = {
    R: "readKey",
    C: "createKey",
    U: "updateKey",
    D: "deleteKey",
  };

  const accessKey = accessTypeMapping[accessType.toUpperCase()];
  if (!accessKey) {
    return false;
  }

  const moduleEntry = moduleArray.find((entry) => entry.moduleName === module);
  if (!moduleEntry) {
    return false;
  }

  return moduleEntry[accessKey] === true;
};
